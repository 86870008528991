import Alpine from 'alpinejs'

interface LazyLoadProducts {
    loading: boolean;
    nextUrl: string;
    itemSelector: string;
    loaderSelector: string;
    wrapperSelector: string;
    fetchMore(): void;
    parseHtml(html: string): Document;
}

export const initLazyLoadProducts = () => {
    Alpine.data('lazyLoadProducts', (nextUrl: string = ''): LazyLoadProducts => ({
        loading: false,
        nextUrl: nextUrl,
        itemSelector: '.search-impa-item',
        loaderSelector: '.search-impa-item--empty',
        wrapperSelector: '.search-impa__wrapper',
        fetchMore(): void {
            if (this.nextUrl) {
                this.loading = true

                fetch(this.nextUrl)
                    .then(response => response.text())
                    .then(res => {
                        const html: Document = this.parseHtml(res)
                        const searchItems: NodeListOf<HTMLElement> = html.querySelectorAll(this.itemSelector)
                        const wrapper: HTMLElement|null = html.querySelector(this.wrapperSelector)

                        if (searchItems && searchItems.length) {
                            searchItems.forEach((searchItem: Element) => {
                                this.$refs.searchItemWrapper.appendChild(searchItem)
                            })
                        }

                        this.nextUrl = ''
                        if (wrapper && wrapper.dataset.hasOwnProperty('nextUrl') && wrapper.dataset.nextUrl) {
                            this.nextUrl = wrapper.dataset.nextUrl
                        }

                        this.loading = false
                    })
                    .catch(error => console.error(error))
            }
        },
        parseHtml(html: string): Document {
            const parser = new DOMParser()
            return parser.parseFromString(html, 'text/html')
        }
    }))
}
